import { AbstractViewModel } from '../../../abstract.view.model';
import { PlaceApiModel } from '../../api-model/place/place.api.model';
import { MenuViewModel } from '../menu/menu.view.model';
import { RoomViewModel } from './room.view.model';
import { ImageApiModel } from '../../api-model/product/image.api.model';
import { WorkingHoursViewModel } from '@core/models/tapp-order/view-model/place/working-hours.view.model';
import { PaymentProviderViewModel } from '@core/models/tapp-order/view-model/place/payment-provider.view.model';
import { CompanyViewModel } from '@core/models/tapp-order/view-model/place/company.view.model';
import { LinkViewModel } from '@core/models/tapp-order/view-model/place/link.view.model';
import { MenuTypeEnum } from '@shared/enum/menu-type.enum';
import { ThemeSettingsViewModel } from '@core/models/tapp-order/view-model/place/theme-settings.view.model';

export class PlaceViewModel extends AbstractViewModel<PlaceApiModel> {
  public street: string;
  public buildingNo: string;
  public localNo: string;
  public zipCode: string;
  public city: string;
  public country: string;
  public image: ImageApiModel;
  public publicId: string;
  public closedUntil: string;
  public name: string;
  public status: string;
  public email: string;
  public phone: string;
  public active: string;
  public type: string;
  public posStatus: string;
  public posStatusCheck: boolean;
  public qrMenuActive: boolean = true;
  public tableOrderActive: boolean;
  public onlineOrderActive: boolean;
  public menus: MenuViewModel[];
  public rooms: RoomViewModel[];
  public workingHours: WorkingHoursViewModel[] = [];
  public deliveryWorkingHours: WorkingHoursViewModel[] = [];
  public pickupWorkingHours: WorkingHoursViewModel[] = [];
  public paymentProviders: PaymentProviderViewModel[] = [];
  public company: CompanyViewModel = null;
  public maxOrderPrice: number;
  public paymentByCardActive: boolean = true;
  public paymentByCashActive: boolean = true;
  public links: LinkViewModel[] = [];
  public qrMenuExpiredAt: Date = null;
  public tableOrdersExpiredAt: Date = null;
  public onlineOrdersExpiredAt: Date = null;
  public autohidePizzaOptions: boolean = true;
  public themeSettings: ThemeSettingsViewModel = null;

  private _isPreviewOnly: boolean = null;

  constructor(public apiModel: PlaceApiModel = new PlaceApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.street = this.apiModel.street;
    this.buildingNo = this.apiModel.buildingNo;
    this.localNo = this.apiModel.localNo;
    this.zipCode = this.apiModel.zipCode;
    this.city = this.apiModel.city;
    this.country = this.apiModel.country;
    this.image = this.apiModel.image;
    this.publicId = this.apiModel.publicId;
    this.closedUntil = this.apiModel.closedUntil;
    this.name = this.apiModel.name;
    this.status = this.apiModel.status;
    this.email = this.apiModel.email;
    this.phone = this.apiModel.phone;
    this.active = this.apiModel.active;
    this.type = this.apiModel.type;
    this.posStatus = this.apiModel.posStatus;
    this.posStatusCheck = this.apiModel.posStatusCheck;
    this.paymentByCardActive = this.apiModel.paymentByCardActive;
    this.paymentByCashActive = this.apiModel.paymentByCashActive;
    this.qrMenuExpiredAt = new Date(this.apiModel.qrMenuExpiredAt);
    this.tableOrdersExpiredAt = new Date(this.apiModel.tableOrdersExpiredAt);
    this.onlineOrdersExpiredAt = new Date(this.apiModel.onlineOrdersExpiredAt);

    this.qrMenuActive = this.qrMenuExpiredAt >= new Date();
    this.tableOrderActive = this.apiModel.tableOrderActive && this.tableOrdersExpiredAt >= new Date();
    this.onlineOrderActive = this.apiModel.onlineOrderActive && this.onlineOrdersExpiredAt >= new Date();

    this.menus = this.apiModel.menus ? this.apiModel.menus.map((menu) => new MenuViewModel(menu)) : [];

    this.rooms = this.apiModel.rooms ? this.apiModel.rooms.map((room) => new RoomViewModel(room)) : [];

    this.workingHours = this.apiModel.workingHours
      ? this.apiModel.workingHours.map((workingHour) => new WorkingHoursViewModel(workingHour))
      : [];

    this.deliveryWorkingHours = this.apiModel.deliveryWorkingHours
      ? this.apiModel.deliveryWorkingHours.map((workingHour) => new WorkingHoursViewModel(workingHour))
      : [];

    this.pickupWorkingHours = this.apiModel.pickupWorkingHours
      ? this.apiModel.pickupWorkingHours.map((workingHour) => new WorkingHoursViewModel(workingHour))
      : [];

    this.paymentProviders = this.apiModel.paymentProviders
      ? this.apiModel.paymentProviders.map((paymentProvider) => new PaymentProviderViewModel(paymentProvider))
      : [];

    this.company = this.apiModel.company ? new CompanyViewModel(this.apiModel.company) : null;

    this.maxOrderPrice = this.apiModel.maxOrderPrice;

    this.links = this.apiModel.links ? this.apiModel.links.map((link) => new LinkViewModel(link)) : [];

    this.autohidePizzaOptions = this.apiModel.autohidePizzaOptions;

    this.themeSettings = this.apiModel.themeSettings ? new ThemeSettingsViewModel(this.apiModel.themeSettings) : null;
  }

  toApiModel(): PlaceApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }

  getMenuByType(type: MenuTypeEnum) {
    if (!this.onlineOrderActive) {
      type = MenuTypeEnum.ONSITE;
    }

    let menu = this.menus.find((menu) => menu.deliveryTypes.includes(type));
    if (!menu) {
      menu = this.menus[0];
      this._isPreviewOnly = true;
    }

    return menu;
  }

  get isPreviewOnly() {
    return this._isPreviewOnly !== null
      ? this._isPreviewOnly
      : sessionStorage.getItem('tableId')
      ? !this.tableOrderActive
      : !this.onlineOrderActive;
  }

  set isPreviewOnly(value: boolean) {
    this._isPreviewOnly = value;
  }

  get tel(): string {
    return this.phone.replace(/\s/g, '');
  }
}
