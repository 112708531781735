import { Injectable } from '@angular/core';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { TermsAndConditionsItemViewModel } from '@core/models/tapp-order/view-model/terms-and-conditions/terms-and-conditions-item.view.model';
import { PaymentMethodTypeEnum } from '@core/pages/payment/enum/payment-method-type.enum';
import { OrderService } from '@core/services/order.service';
import { Observable, of } from 'rxjs';
import { DeliveryZoneService } from '../../../services/deliveryZone.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentFormService {
  private _termsAndConditions: TermsAndConditionsItemViewModel[] = [];
  public paymentApproveRejected: boolean = false;
  public paymentMethodRejected: boolean = false;
  public paymentAddressRejected: boolean = false;
  public paymentCustomerRejected: boolean = false;
  public paymentBlikRejected: boolean = false;
  public paymentInvoiceRejected: boolean = false;
  public payuSdkForms: any;

  constructor(private orderService: OrderService, private deliveryZoneService: DeliveryZoneService) {}

  get isPickupOrderType(): boolean {
    return this.orderService.getOrderType() === OrderTypeEnum.pick_up ? true : false;
  }

  getIsPaymentMethodSelected(): Observable<boolean> {
    if (
      this.orderService.getPaymentMethod() === PaymentMethodTypeEnum.CASH ||
      this.orderService.getPaymentMethod() === PaymentMethodTypeEnum.CARD
    ) {
      return of(true);
    } else {
      if (this.orderService.getPaymentMethodType() === PaymentMethodTypeEnum.BLIK) {
        if (this.orderService.getPaymentMethodValue() && this.orderService.getPaymentMethodValue().length !== 6) {
          this.paymentBlikRejected = true;
          return of(true);
        } else if (
          this.orderService.getPaymentMethodValue() &&
          this.orderService.getPaymentMethodValue().length === 6
        ) {
          this.paymentBlikRejected = false;
          return of(true);
        }

        if (this.orderService.getPaymentMethodValue() === null) {
          this.paymentBlikRejected = true;
          return of(true);
        }

        return of(this.orderService.getPaymentMethodValue() && this.orderService.getPaymentMethodValue().length === 6);
      }
      if (this.orderService.getPaymentMethodType() === PaymentMethodTypeEnum.CARD_TOKEN) {
        if (!this.payuSdkForms) {
          return of(false);
        }
        return new Observable((observer) => {
          this.payuSdkForms.tokenize('SINGLE').then((result) => {
            if (result.status === 'SUCCESS') {
              this.orderService.setPaymentMethodValue(result.body.token);
              observer.next(true);
            } else {
              observer.next(false);
            }
          });
        });
      } else {
        return of(!!this.orderService.getPaymentMethodValue());
      }
    }
  }

  getIsCustomerEmailSelected(): boolean {
    return !!this.orderService.getCustomerEmail();
  }

  getIsCustomerNameSelected(): boolean {
    return !!this.orderService.getCustomerFirstName();
  }

  getIsCustomerPhoneSelected(): boolean {
    return !!this.orderService.getCustomerPhone();
  }

  getIsInvoiceSelected(): boolean {
    return this.orderService.getInvoiceSelected() === 'true' ? true : false;
  }

  getIsAddressSelected(): boolean {
    if (this.isPickupOrderType) {
      return true;
    }

    return !!this.orderService.getDeliveryAddressStreet() && !!this.orderService.getDeliveryZoneId();
  }

  getIsTermsAndConditionsAccepted(): boolean {
    if (this._termsAndConditions.length > 0) {
      return !this._termsAndConditions
        .filter((item) => {
          return item.required;
        })
        .find((item) => {
          return !item.selected;
        });
    } else {
      return true;
    }
  }

  set selectedTermsAndConditions(termsAndConditions: TermsAndConditionsItemViewModel[]) {
    this._termsAndConditions = termsAndConditions;
  }

  get selectedTermsAndConditions(): TermsAndConditionsItemViewModel[] {
    return this._termsAndConditions;
  }

  public isNewOrderCanBeCreated(): Promise<boolean> {
    return new Promise((resolve) => {
      this.paymentAddressRejected = false;
      this.paymentMethodRejected = false;
      this.paymentApproveRejected = false;
      this.paymentCustomerRejected = false;
      this.getIsPaymentMethodSelected().subscribe((value) => {
        if (!this.getIsTermsAndConditionsAccepted()) {
          this.onTermsAndConditionsNotAccepted();
        }
        if (!value) {
          this.onPaymentMethodNotSelected();
        }
        if (
          !this.getIsCustomerEmailSelected() ||
          !this.getIsCustomerNameSelected() ||
          !this.getIsCustomerPhoneSelected()
        ) {
          this.onCustomerDetailsNotSelected();
        }
        if (!this.getIsAddressSelected() && !this.orderService.tableId) {
          this.onDeliveryAddressNotSelected();
        }
        if (this.getIsInvoiceSelected()) {
          this.onInvoiceDataNotSelected();
        }
        if (
          this.paymentAddressRejected ||
          this.paymentMethodRejected ||
          this.paymentApproveRejected ||
          this.paymentCustomerRejected ||
          this.paymentBlikRejected ||
          this.paymentInvoiceRejected
        ) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }

  public onTermsAndConditionsNotAccepted(): void {
    document.getElementById('paymentApprove').scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    this.paymentApproveRejected = true;
  }

  public onPaymentMethodNotSelected(): void {
    document.getElementById('paymentMethod').scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    this.paymentMethodRejected = true;
  }

  public onDeliveryAddressNotSelected(): void {
    if (this.deliveryZoneService._isDeliveryZone) {
      document.getElementById('paymentAddress').scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
      this.paymentAddressRejected = true;
    }
  }

  public onInvoiceDataNotSelected(): void {
    if (
      !this.orderService.getInvoiceNip() ||
      !this.orderService.getInvoiceCompanyName() ||
      !this.orderService.getInvoiceCompanyCity() ||
      !this.orderService.getInvoiceCompanyStreetNo()
    ) {
      this.paymentInvoiceRejected = true;
    } else {
      this.paymentInvoiceRejected = false;
    }
  }

  public onCustomerDetailsNotSelected(): void {
    document.getElementById('paymentAddress').scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    this.paymentCustomerRejected = true;
  }
}
