import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { DeviceDetectorService } from '../../../services/device-detector.service';
import { SettingsDataProvider } from '@core/data-providers/settings.data-provider';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  constructor(
    public deviceService: DeviceDetectorService,
    private settingsDataProvider: SettingsDataProvider,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  ngOnInit() {
    this.settingsDataProvider.getSettings().subscribe({
      next: (settings) => {
        if (settings.chatBotCode) {
          // Create a div element to hold the script content
          const div = this.renderer.createElement('div');
          div.innerHTML = settings.chatBotCode; // Insert raw JS code (including <script> tags)

          // Append the div to the DOM
          this.renderer.appendChild(this.el.nativeElement, div);

          // Extract and append the <script> tags to execute them
          const scripts: any[] = div.getElementsByTagName('script');
          Array.from(scripts).forEach((script) => {
            const scriptClone = this.renderer.createElement('script');
            scriptClone.text = script.innerHTML; // Copy the script content
            this.renderer.appendChild(this.el.nativeElement, scriptClone);
          });
        }
      },
    });
  }
}
