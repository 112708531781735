<!-- Navigation by product groups -->
<app-product-group
  id="app-navbar"
  class="navigator"
  [groups]="menu.groups"
  [page]="'discounts'"
  [qrMenuMode]="qrMenuMode"
  *ngIf="menu"
></app-product-group>

<div id="products-container" class="content">
  <div class="navigator-empty-area" *ngIf="menu"></div>
  <ng-container *ngTemplateOutlet="closeAlert"></ng-container>

  <div class="search-wrapper">
    <ng-container *ngTemplateOutlet="currentLocalLabel"></ng-container>
  </div>

  <div class="group">
    <span class="group__title">
      <div class="group__title__background-text">
        {{ 'tapp-order.pages.discount.common.discounts' | translate | uppercase }}
      </div>
      <div class="group__title__front">
        <h2 class="group__title__front__text">
          {{ 'tapp-order.pages.discount.common.discounts' | translate | uppercase }}
        </h2>
      </div>
    </span>
    <div class="group__products">
      <app-discount-card
        *ngFor="let discount of discounts"
        [discount]="discount"
        (onAdd)="handleAddDiscount(discount)"
        [buttonVisible]="isOpen && !placeIsPreviewOnly && !qrMenuMode"
      ></app-discount-card>
    </div>
  </div>

  <div class="buttom-button-empty-area" *ngIf="menu"></div>
</div>

<!-- Close alert -->
<ng-template #closeAlert>
  <div class="close-alert" *ngIf="!isOpen">
    <span *ngIf="!nextWorkingHours">{{ 'products.local-closed-message' | translate }}</span>
    <span *ngIf="nextWorkingHours">
      {{ 'products.local-closed-message-2' | translate }}
      <b>
        {{ 'app.weekDays.' + nextWorkingHours.weekDay | translate }}
        {{ nextWorkingHours.startTime }}</b
      >.
    </span>
    <span> {{ 'products.local-closed-message-3' | translate }}</span><span *ngIf="place.phone">: </span>
    <a *ngIf="place.phone" href="tel:{{ place.tel }}">{{ place.phone }}</a>
  </div>
</ng-template>

<!-- Basket -->
<app-sidebar
  *ngIf="place && (place.onlineOrderActive || orderService.tableId) && !qrMenuMode"
  class="basket"
  [basketItemsAmount]="basketService.calculateAllItemsInBasket()"
  [basketItems]="basketService.basketItems$ | async"
  [basketItemsPrice]="basketService.calculatePrice()"
  [nextWorkingHours]="nextWorkingHours"
  [isOpen]="isOpen"
  [productsCrossSell]="productsCrossSell.length"
  (openCrossSellDialogEvent)="showCrossSellProducts()"
></app-sidebar>

<!-- Button show basket -->
<div
  *ngIf="
    bottomNotificationComponent.active == false &&
    placeIsPreviewOnly == false &&
    !qrMenuMode &&
    filteredMenu &&
    isOpen &&
    hasMenu &&
    (basketService.foodTotalPrice$ | async) > 0
  "
  class="button-show-basket"
  (click)="navigateToPayment()"
>
  <div class="left">
    <i class="ic ic-basket"></i>
    <span class="order-btn">{{ 'tapp-order.sidebar-basket-header' | translate }}</span>
  </div>
  <span class="right">{{ basketService.finalPrice$ | async | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
</div>

<!-- Dialog configurator -->
<app-discount-dialog-configurator #appDiscountDialogConfigurator></app-discount-dialog-configurator>

<!-- Dialog cross-sell -->
<app-dialog [(visible)]="displayCrossSellDialog" [closable]="false" (onHide)="closeCrossSellProducts()">
  <ng-template #header>
    <div class="dialog-cross-sell__header">{{ 'tapp-order.pages.product.dialog.cross-sell.header' | translate }}</div>
  </ng-template>

  <div class="dialog-cross-sell__content">
    <app-product-item
      *ngFor="let product of productsCrossSell"
      [data]="product"
      [buttonVisible]="true"
      (onAdd)="handleAddProductToBasket(product)"
    ></app-product-item>
  </div>

  <ng-template #footer>
    <div class="dialog-cross-sell__footer">
      <button
        pButton
        class="p-button"
        type="button"
        [label]="'tapp-order.pages.product.dialog.cross-sell.button-label' | translate"
        (click)="displayCrossSellDialog = false"
      ></button>
    </div>
  </ng-template>
</app-dialog>

<ng-template #currentLocalLabel>
  <div
    class="col-12"
    *ngIf="orderService.tableId"
    style="
      margin-top: 1rem;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: var(--font-placeholder);
    "
  >
    {{ 'tapp-order.order-summary-room' | translate }}: {{ orderService.roomName }} <br />
    {{ 'tapp-order.order-summary-table' | translate }}:
    {{ orderService.tableName }}
  </div>

  <p
    *ngIf="!orderService.tableId && place && place.onlineOrderActive"
    style="
      margin-top: 1rem;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: var(--font-placeholder);
    "
  >
    {{ 'products.local-realizing-order-label' | translate }}:
    <span
      style="
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: var(--primary);
        cursor: pointer;
      "
      (click)="changeLocal()"
      >{{ placeString ? placeString : null }}</span
    >
  </p>
</ng-template>

<!-- Dialog address selection -->
<app-dialog-address-finder
  [displayDialog]="displayDialog"
  [redirectToPlace]="true"
  (onCloseDialog)="closeDialog()"
  [message1]="'tapp-order.select-adress-component.dialog.adress-change-warning-1' | translate"
  [message2]="'tapp-order.select-adress-component.dialog.adress-change-warning-3' | translate"
></app-dialog-address-finder>
