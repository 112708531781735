import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { VersionService } from '@core/services/version.service';
import { environment } from '@env/environment';
import { PrimeNGConfig } from 'primeng/api';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { SwUpdateService } from './core/services/update.service';
import { LanguageService } from './services/language.service';
import { MigrationService } from './services/migration.service';
import { NavigationService } from './services/navigation.service';
import { PlaceService } from './services/place.service';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public readonly environment = environment;

  constructor(
    private primengConfig: PrimeNGConfig,
    private versionService: VersionService,
    private languageService: LanguageService,
    private migrationService: MigrationService,
    private navigationService: NavigationService,
    private placeService: PlaceService,
    protected swUpdateService: SwUpdateService,
    public themeService: ThemeService,
  ) {
    this.migrationService.migrateApp();
    this.versionService.init();
    this.navigationService.init();

    this.primengConfig.ripple = true;

    this.languageService.initLanguage();
  }

  ngOnInit() {
    this.placeService.init();

    if (this.placeService.isOnline) {
      globalCacheBusterNotifier.next();
    }

    this.detectIOS();

    window.addEventListener('scroll', function () {
      navbarScroll();
    });

    function navbarScroll() {
      const el = document.getElementsByClassName('header-rollup');
      if (el) {
        const header = el[0];
        if (header) {
          if (window.scrollY > 10) {
            header.classList.add('rollup');
          } else {
            header.classList.remove('rollup');
          }
        }
      }
    }
  }

  private detectIOS() {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => 'standalone' in (window as any).navigator && (window as any).navigator.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      setTimeout(() => {
        //na chwilę obecną ukrywamy ten komunikat
        //this.notificationService.showIosInstall();
      });
    }
  }
}
